import { SESSION_BASE_FRAGMENT } from '@/graphql/_Fragments/Session/Base';

export const SURVEY_WITH_SESSION_FRAGMENT = `
  fragment surveyWithSessionFragment on Survey {
    uid
    code
    title
    strategy
    instructions
    startTime
    startTimestamp
    endTime
    endTimestamp
    session {
      ...sessionBaseFragment
    }
  }
  ${SESSION_BASE_FRAGMENT}
`;
